import ExcelJS from 'exceljs';

export const IndividualTrainingHoursXlsx = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Training Data');

    const headers = [
        'รหัสพนักงาน',
        'ชื่อ',
        'นามสกุล',
        'เลขบัตรประชาชน',
        'ฝ่าย',
        'ส่วน',
        'แผนก',
        'ตำแหน่ง',
        'ชั่วโมงเรียนในห้อง',
        'ชั่วโมงเรียนออนไลน์',
        'ชั่วโมง OJT',
        'รวมชั่วโมงเรียน',
        'ชั่วโมงเรียนเฉพาะหลักสูตรที่ส่งราชการ',
        'ชั่วโมงเรียนในห้องทั้งปี',
        'ชั่วโมงเรียนออนไลน์ทั้งปี',
        'ชั่วโมง OJTทั้งปี',
        'รวมชั่วโมงเรียนทั้งปี',
        'ชั่วโมงเรียนเฉพาะหลักสูตรที่ส่งราชการทั้งปี'
    ];

    const headerRow = worksheet.addRow(headers);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const data = [
        [101, 'John', 'Doe', '1234567890123', 'ฝ่าย 1', 'ส่วน 1', 'แผนก 1', 'ตำแหน่ง 1', 10, 20, 30, 60, 15, 100, 200, 300, 600, 50],
        [102, 'Jane', 'Smith', '9876543210987', 'ฝ่าย 2', 'ส่วน 2', 'แผนก 2', 'ตำแหน่ง 2', 8, 25, 28, 61, 14, 90, 210, 290, 590, 48],
    ];

    data.forEach((row) => {
        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    worksheet.columns = headers.map(header => ({
        header,
        width: header.length + 10, // Adjust column width based on header length
    }));

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Individual TrainingHours.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};