import React from "react";
import ListMenu from "../../../shared/listMenu";

import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import BadgeIcon from '@mui/icons-material/Badge';

import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import LocalLibraryRoundedIcon from '@mui/icons-material/LocalLibraryRounded';
// import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

import QuizIcon from '@mui/icons-material/Quiz';
import HistoryEduRoundedIcon from '@mui/icons-material/HistoryEduRounded';
import AlarmOnRoundedIcon from '@mui/icons-material/AlarmOnRounded';

import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import PersonalVideoRoundedIcon from '@mui/icons-material/PersonalVideoRounded';

const AdminMenu = () => {
  const dataListMenu = [
    // {
    //   listItemIcon: <AddRoundedIcon />,
    //   listItemText: "สร้างหลักสูตร",
    //   listLink: "",
    //   listKey: "AddCourse",
    //   collapse: [
    //     {
    //       listItemIcon: <MenuBookRoundedIcon />,
    //       listItemText: "หลักสูตร Classroom",
    //       listLink: "/AddCourse/classroom",
    //       listKey: "AddCourse-classroom",
    //     },
    //     {
    //       listItemIcon: <ComputerRoundedIcon />,
    //       listItemText: "หลักสูตร Online",
    //       listLink: "/AddCourse/online",
    //       listKey: "AddCourse-online",
    //     },
    //   ],
    // },
    {
      listItemIcon: <DashboardRoundedIcon />,
      listItemText: "Dashboard",
      listLink: "/Admin/Dashboard",
      listKey: "admin-dashboard",
    },
    // {
    //   listItemIcon: <DashboardRoundedIcon />,
    //   listItemText: "Dashboard",
    //   listLink: "",
    //   listKey: "Dashboard",
    //   collapse: [
    //     {
    //       listItemIcon: <DashboardIcon />,
    //       listItemText: "Dashboard Overview",
    //       listLink: "/Admin/Dashboard",
    //       listKey: "Dashboard",
    //     },
    //     {
    //       listItemIcon: <VerifiedUserIcon />,
    //       listItemText: "Dashboard Cost",
    //       listLink: "/Admin/DashboardCost",
    //       listKey: "DashboardCost",
    //     },
    //   ],
    // },
    {
      listItemIcon: <EditRoundedIcon />,
      listItemText: "จัดการหลักสูตร",
      listLink: "",
      listKey: "ManageCourse",
      collapse: [
        {
          listItemIcon: <AddRoundedIcon />,
          listItemText: "สร้างหลักสูตร",
          listLink: "/Admin/openCourse",
          listKey: "MandatorySetting",
        },
        {
          listItemIcon: <SchoolRoundedIcon />,
          listItemText: "หลักสูตรที่กำลังเปิด",
          listLink: "/Admin/activeCourse",
          listKey: "AdminActiveCourse",
        },
        // {
        //   listItemIcon: <HowToRegRoundedIcon />,
        //   // listItemIcon: <ContentPasteRoundedIcon />,
        //   listItemText: "พนักงานลงทะเบียน",
        //   listLink: "/EmployeeRegister",
        //   listKey: "EmployeeRegister",
        // },
        // {
        //   listItemIcon: <RuleRoundedIcon />,
        //   listItemText: "พนักงานเข้าร่วม",
        //   listLink: "/AttendTrainingCourses",
        //   listKey: "AttendTrainingCourses",
        // },
      ],
    },
    {
      listItemIcon: <HistoryRoundedIcon />,
      listItemText: "ประวัติต่างๆ",
      listLink: "",
      listKey: "AllHistory",
      collapse: [
        {
          listItemIcon: <PersonalVideoRoundedIcon />,
          listItemText: "ประวัติอบรมในระบบ",
          listLink: "/Admin/History/Internal",
          listKey: "HistoryExternal",
        },
        {
          listItemIcon: <BusinessRoundedIcon />,
          listItemText: "ประวัติอบรมนอกระบบ",
          listLink: "/Admin/History/External",
          listKey: "HistoryExternal",
        },
        {
          listItemIcon: <HistoryRoundedIcon />,
          listItemText: "ประวัติการเปิดหลักสูตร",
          listLink: "/Admin/History/Courses",
          listKey: "HistoryCourses",
        },
        // {
        //   listItemIcon: <HistoryRoundedIcon />,
        //   listItemText: "ประวัติการเรียนทั้งหมด",
        //   listLink: "/Admin/EmplearnHistory",
        //   listKey: "admin-learnHistory",
        // },
      ],
    },
    {
      listItemIcon: <AssignmentTurnedInRoundedIcon />,
      listItemText: "รายการ OJT",
      listLink: "/Admin/OJT/AllOnJobTraining",
      listKey: "all-onjobtraining",
    },
    // {
    //   listItemIcon: <AssignmentTurnedInRoundedIcon />,
    //   listItemText: "On Job Training (OJT)",
    //   listLink: "",
    //   listKey: "on-job-training",
    //   collapse: [
    //     // {
    //     //   listItemIcon: <AssignmentTurnedInRoundedIcon />,
    //     //   listItemText: "กำหนด OJT",
    //     //   listLink: "/Admin/OJT/createOJT",
    //     //   listKey: "ojt-createOJT",
    //     // },
    //     {
    //       listItemIcon: <AssignmentTurnedInRoundedIcon />,
    //       listItemText: "รายการ OJT",
    //       listLink: "/Admin/OJT/AllOnJobTraining",
    //       listKey: "all-onjobtraining",
    //     },
    //     {
    //       listItemIcon: <AssignmentTurnedInRoundedIcon />,
    //       listItemText: "รายการสถานะ",
    //       listLink: "/Admin/OJT/view-allstatus",
    //       listKey: "all-onjobtraining-status",
    //     },
    //   ],
    // },
    {
      listItemIcon: <PsychologyRoundedIcon />,
      listItemText: "ความสามารถ",
      listLink: "",
      listKey: "headCompetency",
      collapse: [
        //  {
        //    listItemIcon: <AddRoundedIcon />,
        //    listItemText: "เปิดความสามารถใหม่",
        //    listLink: "/Admin/Competency/AddCompetency",
        //    listKey: "add-competency",
        //  },
        // {
        //   listItemIcon: <AssistantRoundedIcon />,
        //   listItemText: "ความสามารถแนะนำ",
        //   listLink: "/Admin/Competency/Suggest-Competency",
        //   listKey: "suggest-competencys",
        //   // disabled: true,
        // },
        {
          listItemIcon: <PsychologyRoundedIcon />,
          listItemText: "ความสามารถทั้งหมด",
          listLink: "/Admin/Competency/AllCompetency",
          listKey: "all-competencys",
        },
        {
          listItemIcon: <HowToRegIcon />,
          listItemText: "รายการความสามารถ",
          listLink: "/Admin/Competency/StatusCompetency",
          listKey: "list-evaluate-competency",
        },
      ],
    },
    {
      listItemIcon: <SettingsSuggestRoundedIcon />,
      listItemText: "ทักษะ",
      listLink: "",
      listKey: "headSkill",
      collapse: [
        // {
        //   listItemIcon: <AddRoundedIcon />,
        //   listItemText: "เปิดทักษะใหม่",
        //   listLink: "/Admin/skill/AddSkill",
        //   listKey: "add-skill",
        // },
        {
          listItemIcon: <SettingsSuggestRoundedIcon />,
          listItemText: "ทักษะทั้งหมด",
          listLink: "/Admin/skill/Allskill",
          listKey: "all-skills",
        },
        {
          listItemIcon: <SettingsSuggestRoundedIcon />,
          listItemText: "Skill Mapping",
          listLink: "/Admin/skill/SkillMapping",
          listKey: "skill-mapping",
        },
        // {
        //   listItemIcon: <EventNoteRoundedIcon />,
        //   listItemText: "รายการนัดหมายทั้งหมด",
        //   listLink: "/testAdminListappoint",
        //   listKey: "testAdminListappoint",
        // },
      ],
    },
    {
      listItemIcon: <Groups2RoundedIcon />,
      listItemText: "ข้อมูลการเรียนรู้รายบุคคล",
      listLink: "/Admin/person/Employee",
      listKey: "EmployeesLearningInfomation",
    },
    {
      listItemIcon: <Groups2RoundedIcon />,
      listItemText: "รายงาน",
      listLink: "/Admin/report-center",
      listKey: "report-center",
    },
    // {
    //   listItemIcon: <Groups2RoundedIcon />,
    //   listItemText: "แผนพัฒนาบุคคล",
    //   listLink: "",
    //   listKey: "headIDP",
    //   collapse: [
    //     {
    //       listItemIcon: <QuestionAnswerRoundedIcon />,
    //       listItemText: "แผนพัฒนารายบุคลทั้งหมด",
    //       listLink: "/all-idp",
    //       listKey: "all-idp",
    //     },
    //     // {
    //     //   listItemIcon: <ContentPasteSearchRoundedIcon />,
    //     //   listItemText: "รายการรออนุมัติ",
    //     //   listLink: "/idp-approval",
    //     //   listKey: "idp-approval",
    //     // },
    //   ],
    // },
    {
      listItemIcon: <PsychologyAltIcon />,
      listItemText: "แบบทดสอบศักยภาพ",
      listLink: "/Admin/Potential",
      listKey: "All-Potential",
      disabled: true,
    },
    {
      listItemIcon: <QuizIcon />,
      listItemText: "Quiz",
      listLink: "",
      listKey: "Quiz",
      collapse: [
        {
          listItemIcon: <QuizIcon />,
          // listItemIcon: <HistoryEduRoundedIcon />,
          // listItemText: "คำถามท้ายบทเรียน",
          listItemText: "Lesson Quiz",
          listLink: "/Admin/LessonQuiz",
          listKey: "AdminLessonQuiz",
        },
        {
          listItemIcon: <AlarmOnRoundedIcon />,
          listItemText: "Rush Quiz",
          listLink: "/Admin/RushQuiz",
          listKey: "AdminRushQuiz",
          // disabled: true,
        },
      ],
    },
    {
      listItemIcon: <BadgeIcon />,
      listItemText: "ใบอนุญาต",
      listLink: "",
      listKey: "headLicense",
      collapse: [
        {
          listItemIcon: <AddRoundedIcon />,
          listItemText: "เพิ่มใบอนุญาต",
          listLink: "/Admin/license/AddLicense",
          listKey: "add-license",
        },
        // {
        //   listItemIcon: <SettingsSuggestRoundedIcon />,
        //   listItemText: "ใบอนุญาตพนักงาน",
        //   listLink: "/Admin/license/emp-license",
        //   listKey: "all-license",
        // },
      ],
    },
    // {
    //   listItemIcon: <PsychologyRoundedIcon />,
    //   listItemText: "Competency",
    //   listLink: "",
    //   listKey: "headCompetency",
    //   collapse: [
    //     {
    //       listItemIcon: <PsychologyRoundedIcon />,
    //       listItemText: "Competency ทั้งหมด",
    //       listLink: "/testAdminCompetency",
    //       listKey: "AllCompetency",
    //     },
    //     {
    //       listItemIcon: <HowToRegRoundedIcon />,
    //       listItemText: "รายการรอประเมิน",
    //       listLink: "/testAdminCompetency-approval",
    //       listKey: "AdminCompetency-approval",
    //     },
    //   ],
    // },
    {
      listItemIcon: <EventNoteRoundedIcon />,
      listItemText: "สถานะรายการ",
      listLink: "/Admin/Status/StatusAdmin",
      listKey: "admin-status",
    },
    {
      listItemIcon: <PeopleAltRoundedIcon />,
      listItemText: "จัดการบุคลากร",
      listLink: "",
      listKey: "managePerson",
      collapse: [
        {
          listItemIcon: <PersonAddAlt1RoundedIcon />,
          listItemText: "รายชื่อผู้สอน",
          listLink: "/Admin/person/Teacher",
          listKey: "AddTeacher",
        },
        {
          listItemIcon: <PersonAddAlt1RoundedIcon />,
          // listItemIcon: <ManageAccountsRoundedIcon />,
          listItemText: "รายชื่อผู้ประเมิน",
          listLink: "/Admin/person/Assessor",
          listKey: "AddAssessor",
        },
        {
          listItemIcon: <LocalLibraryRoundedIcon />,
          // listItemText: "จัดการตำแหน่งและรายบุคคล",
          listItemText: "จัดการเข้าตำแหน่ง",
          listLink: "/Admin/LearningManagement",
          listKey: "AdminLearningManagement",
        },
      ],
    },
    // {
    //   listItemIcon: <DescriptionRoundedIcon />,
    //   listItemText: "บิล",
    //   listLink: "",
    //   listKey: "headBill",
    //   collapse: [
    //     {
    //       listItemIcon: <AttachMoneyRoundedIcon />,
    //       listItemText: "รายการรอจ่าย",
    //       listLink: "/testAdminAttachMoney",
    //       listKey: "testAdminAttachMoney",
    //     },
    //     {
    //       listItemIcon: <ReceiptLongRoundedIcon />,
    //       listItemText: "ประวัติรายการ",
    //       listLink: "/testListHistoryBill",
    //       listKey: "testListHistoryBill",
    //     },
    //   ],
    // },
  ];

  return <ListMenu dataListMenu={dataListMenu} menuRole={"Admin"} />;
};

export default AdminMenu;
