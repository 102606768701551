import React from "react";
import ListMenu from "../../../shared/listMenu";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DvrIcon from '@mui/icons-material/Dvr';
import WorkIcon from "@mui/icons-material/Work";
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import { RequestPage } from "@mui/icons-material";

const ManagerMenu = () => {
  const dataListMenu = [
    // {
    //   listItemIcon: <DoneAllIcon />,
    //   listItemText: "รายการรออนุมัติ",
    //   listLink: "/manager/approve",
    //   listKey: "approvelist",
    // },
    {
      listItemIcon: <Groups2RoundedIcon />,
      listItemText: "พนักงานในทีม",
      listLink: "/manager/Employee",
      listKey: "team-infomation",
    },
    // {
    //   listItemIcon: <ContentPasteSearchRoundedIcon />,
    //   listItemText: "รายการความสามารถ",
    //   listLink: "/manager/Competency/TeamCompetency",
    //   listKey: "list-team-competency",
    // },
    // {
    //   listItemIcon: <ContentPasteRoundedIcon />,
    //   listItemText: "Leadership Competency",
    //   listLink: "/manager/Competency/Leader",
    //   listKey: "competency-leader-evaluate",
    // },
    {
      listItemIcon: <ContentPasteSearchRoundedIcon />,
      listItemText: "Competency",
      listLink: "",
      listKey: "competency",
      collapse: [
        {
          listItemIcon: <ContentPasteSearchRoundedIcon />,
          listItemText: "Card Competency",
          listLink: "/manager/Competency/TeamCompetency",
          listKey: "manager-team-competency",
        },
        {
          listItemIcon: <ContentPasteRoundedIcon />,
          listItemText: "Leadership Competency",
          listLink: "/manager/Competency/Leader",
          listKey: "manager-leader-competency",
        },
      ],
    },
    {
      listItemIcon: <AssignmentTurnedInRoundedIcon />,
      listItemText: "รายการ OJT",
      listLink: "/manager/OJT/AllOnJobTraining",
      listKey: "manager-all-onjobtraining",
    },
    // {
    //   listItemIcon: <AssignmentTurnedInRoundedIcon />,
    //   listItemText: "Manager OJT",
    //   listLink: "",
    //   listKey: "on-job-training",
    //   collapse: [
    //     // {
    //     //   listItemIcon: <AssignmentTurnedInRoundedIcon />,
    //     //   listItemText: "กำหนด OJT",
    //     //   listLink: "/manager/OJT/createOJT",
    //     //   listKey: "manager-ojt-createOJT",
    //     // },
    //     {
    //       listItemIcon: <AssignmentTurnedInRoundedIcon />,
    //       listItemText: "รายการ OJT",
    //       listLink: "/manager/OJT/AllOnJobTraining",
    //       listKey: "manager-all-onjobtraining",
    //     },
    //     // {
    //     //   listItemIcon: <AssignmentTurnedInRoundedIcon />,
    //     //   listItemText: "รายการสถานะ",
    //     //   listLink: "/manager/OJT/view-allstatus",
    //     //   listKey: "manager-all-onjobtraining-status",
    //     // },
    //   ],
    // },
    {
      listItemIcon: <EventNoteRoundedIcon />,
      listItemText: "สถานะรายการ",
      listLink: "/manager/Status/StatusManager",
      listKey: "manager-status",
    },
    // {
    //   listItemIcon: <Groups2RoundedIcon />,
    //   listItemText: "การ์ดความสามารถ",
    //   listLink: "/manager/Competency/CardsCompetency",
    //   listKey: "card-competency",
    // },

    // {
    //   listItemIcon: <Groups2RoundedIcon />,
    //   listItemText: "แผนพัฒนาบุคคล",
    //   listLink: "",
    //   listKey: "headIDP",
    //   collapse: [
    //     {
    //       listItemIcon: <QuestionAnswerRoundedIcon />,
    //       listItemText: "แผนพัฒนารายบุคลทีม",
    //       listLink: "/all-idp",
    //       listKey: "all-idp",
    //     },
    //     {
    //       // listItemIcon: <ContentPasteSearchRoundedIcon />,
    //       listItemIcon: <DoneAllIcon />,
    //       listItemText: "รออนุมัติแผนพัฒนา",
    //       listLink: "/idp-approval",
    //       listKey: "idp-approval",
    //     },
    //   ],
    // },
    
    // {
    //   listItemIcon: <DashboardIcon />,
    //   listItemText: "พนักงาน",
    //   listLink: "",
    //   listKey: "employee",
    //   collapse: [
    //     {
    //       listItemIcon: <Filter1Icon />,
    //       listItemText: "รายชื่อพนักงาน",
    //       listLink: "/manager/employee",
    //       listKey: "manager-employee",
    //     },
    //     {
    //       listItemIcon: <Filter2Icon />,
    //       listItemText: "สลับกะพนักงาน",
    //       listLink: "/manager/switch-shift",
    //       listKey: "manager-switch-shift",
    //     },
    //   ],
    // },
  ];

  return <ListMenu dataListMenu={dataListMenu} menuRole={"Manager"} />;
};

export default ManagerMenu;
