import { Container } from "@mui/material";
import React, { Fragment, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import AllReportCenter from "./views/Admin/report-center";
import { IndividualTrainingHours } from "./views/Admin/report-center/IndividualTrainingHours";
import { IndividualTrainingHoursPage } from "./views/Admin/report-center/IndividualTrainingHoursPage";

//Admin
const AddCourse = React.lazy(() =>
  import("./views/Admin/AddCourse")
);
const AssignCourse = React.lazy(() =>
  import("./views/Admin/AssignCourse")
);
const PositionCourse = React.lazy(() =>
  import("./views/Admin/AssignCourse/PositionCourse")
);
const EmployeeCourse = React.lazy(() =>
  import("./views/Admin/AssignCourse/EmployeeCourse")
);
const EmployeeSkill = React.lazy(() =>
  import("./views/Admin/AssignCourse/EmployeeSkill")
);
const ActiveCourse = React.lazy(() =>
  import("./views/Admin/ManageCourse/ListActiveCourse")
);
const ListOpenAll = React.lazy(() =>
  import("./views/Admin/ManageCourse/ListOpenAll")
);
const ListopenCourse = React.lazy(()=>
  import("./views/Admin/ManageCourse/ListopenCourse")
)
const openCourse = React.lazy(()=>
  import("./views/Admin/ManageCourse/OpenCourse")
)
const editMandatory = React.lazy(() =>
  import("./views/Admin/ManageCourse/editMandatory")
);
const editCourse = React.lazy(() =>
  import("./views/Admin/ManageCourse/editmain")
);
const editVDO = React.lazy(() =>
  import("./views/Admin/ManageCourse/component/manageVideo")
);
const EmpRegister = React.lazy(()=>
  import("./views/Admin/ManageCourse/attendanceTable")
)
const ListExternal = React.lazy(()=>
  import("./views/Admin/HistoryCourses/ListExternal")
)
const AddExternalHistory = React.lazy(()=>
  import("./views/Admin/HistoryCourses/AddExternal")
)
const AddInternalHistory = React.lazy(()=>
  import("./views/Admin/HistoryCourses/AddInternal")
)
const HistoryCourses = React.lazy(() =>
  import("./views/Admin/HistoryCourses/HistoryCourses")
);
const AddLicense = React.lazy(()=>
  import("./views/Admin/License/AddLicense")
)
const EmpLicense = React.lazy(()=>
  import("./views/Admin/License/EmpLicense")
)
const licensedetail = React.lazy(()=>
  import("./views/Admin/License/LicenseDetail")
)
const EmpCertificate = React.lazy(()=>
  import("./views/Admin/Certificate/EmpCertificate")
)
const EmpCertificatedetail = React.lazy(()=>
  import("./views/Admin/Certificate/CertificateDetail")
)
// const EmployeeInfo = React.lazy(()=>
//   import("./views/Admin/Personnel/AllEmp")
// )
const EmployeeInfo = React.lazy(()=>
  import("./views/Main-Page/employee")
)

const LessonQuiz = React.lazy(() => import("./views/Admin/Quiz/LessonQuiz"))
const AddLessonQuestion = React.lazy(() => import("./views/Admin/Quiz/LessonAddQuestion"))
const RushQuiz = React.lazy(() => import("./views/Admin/Quiz/RushQuiz"));
const RushAddQuestion = React.lazy(() => import("./views/Admin/Quiz/RushAddQuestion"))

const AddSkill = React.lazy(() => import("./views/Admin/Skills/AddSkill"));
const AdminAllSkill = React.lazy(() => import("./views/Admin/Skills/AllSkill"));
const EmpSkill = React.lazy(() => import("./views/Admin/Skills/EmpSkill"));
const SkillSetting = React.lazy(() => import("./views/Admin/AssignCourse/SkillSetting"));
const SkillMapping = React.lazy(() => import("./views/Admin/Skills/SkillMapping"))
const AddAssessor = React.lazy(() =>
  import("./views/Admin/Personnel/ManageAssessor")
);
const ProfileTeacher = React.lazy(() =>
  import("./views/Admin/Personnel/ProfileTeacher")
);
const ListTeacher = React.lazy(() =>
  import("./views/Admin/Personnel/ManageTeacher")
);
const AddTeacher = React.lazy(() =>
  import("./views/Admin/Personnel/AddTeacher")
);
const EditTeacher = React.lazy(() =>
  import("./views/Admin/Personnel/EditTeacher")
);
// const ManageTeacher = React.lazy(() =>
//   import("./views/Admin/Teachers/ManageTeacher")
// );
// const EditCourseOnline = React.lazy(() =>
//   import("./views/Admin/AddCourse/EditCourseOnline")
// );
// const EditCourseClass = React.lazy(() =>
//   import("./views/Admin/AddCourse/EditCourseClass")
// );
const ViewSkill = React.lazy(() => import("./views/Admin/Skills/AdminSkillDetail"));
const EditInfo = React.lazy(() => import("./views/Admin/Skills/EditSkill-Info"));
const EditLevel = React.lazy(() => import("./views/Admin/Skills/EditSkill-Level"));

const SuggestCompetency = React.lazy(() => import("./views/Admin/Competency/SuggestCompetency"));
const AddCompetency = React.lazy(() => import("./views/Admin/Competency/AddCompetency"));
const AdminAllCompetency = React.lazy(() => import("./views/Admin/Competency/AllCompetency"));
const ViewCompetency = React.lazy(() => import("./views/Admin/Competency/AdminCompetencyDetail"));
const EditCompetencyInfo = React.lazy(() => import("./views/Admin/Competency/EditCompetency-Info"));
const EditCompetencyLevel = React.lazy(() => import("./views/Admin/Competency/EditCompetency-Level"));
const CompetencySetting = React.lazy(() => import("./views/Admin/AssignCourse/CompetencySetting"));
const EmpCompetencyList = React.lazy(() => import("./views/Admin/Competency/EmpCompetencyList"));
const EmpCardsCompetency = React.lazy(() => import("./views/Admin/Competency/EmpCardsCompetency"));
const LeaderCardsCompetency = React.lazy(() => import("./views/Admin/Competency/LeaderCardsCompetency"));

const PotentialAdmin = React.lazy(() => import("./views/Admin/Potential/PotentialTest"))

const AdminCreateOJT = React.lazy(() => import("./views/Admin/OJT/CreateOJT"));
const AdminAllOJT = React.lazy(() => import("./views/Admin/OJT/AllOJT"));
const AdminStatusOJT = React.lazy(() => import("./views/Admin/OJT/StatusOJT"));
const AdminAllStatusOJT = React.lazy(() => import("./views/Admin/OJT/AllStatusOJT"));
const AdminAssignOJT = React.lazy(() => import("./views/Admin/OJT/AssignUser"));
const AdminEditOJT = React.lazy(() => import("./views/Admin/OJT/EditInfo"));
const AdminDuplicateOJT = React.lazy(() => import("./views/Admin/OJT/DuplicateOJT"));

const Dasdboard = React.lazy(() => import("./views/Admin/Dashboard"))
const StatusAdmin = React.lazy(() => import("./views/Admin/Status/StatusAdmin"));

//Manager
// const EmpHistoryList = React.lazy(() =>
//   import("./views/EmpHistoryCourses/EmpHistoryList")
// );
// const TeamLearnHistory = React.lazy(() =>
//   import("./views/Manager/TeamLearnHistory/TeamHistory")
// );
const EmpHistoryCourse = React.lazy(() =>
  import("./views/Manager/TeamLearnHistory/EmpHistoryList")
);
// const ManagerApprove = React.lazy(() =>
//   import("./views/Manager/ApproveList")
// );
const TeamCompetencyList = React.lazy(() => import("./views/Manager/TeamCompetency/TeamCompetencyList"));
const TeamCardsCompetency = React.lazy(() => import("./views/Manager/TeamCompetency/CardsCompetency"));
const TeamCompetencyEvaluate = React.lazy(() => import("./views/Manager/TeamCompetency/CompetencyEvaluate"));
const CompetencyLeader = React.lazy(() => import("./views/Manager/TeamCompetency/CompetencyLeader"));
const CompetencyLeaderView = React.lazy(() => import("./views/Manager/TeamCompetency/CompetencyLeaderView"));
const TeamCreateOJT = React.lazy(() => import("./views/Manager/TeamOJT/CreateOJT"));
const TeamAllOJT = React.lazy(() => import("./views/Manager/TeamOJT/AllOJT"));
const TeamStatusOJT = React.lazy(() => import("./views/Manager/TeamOJT/StatusOJT"));
const TeamAllStatusOJT = React.lazy(() => import("./views/Manager/TeamOJT/AllStatusOJT"));
const TeamAssignOJT = React.lazy(() => import("./views/Manager/TeamOJT/AssignUser"));
const TeamEditOJT = React.lazy(() => import("./views/Manager/TeamOJT/EditInfo"));
const TeamDuplicateOJT = React.lazy(() => import("./views/Manager/TeamOJT/DuplicateOJT"));

const StatusManager = React.lazy(() => import("./views/Manager/Status/StatusManager"));

//User
const AllCourse = React.lazy(()=> import("./views/User/Homepage/AllCourse"));
const Usercategory = React.lazy(() => import("./views/User/Homepage/categorypage"));
const MyAssignCourse = React.lazy(() => import("./views/User/MyCourse/assignCourse"));
const MyAssignCourseCode = React.lazy(() => import("./views/User/MyCourse/assignCourseCode"));
// const MyAssignCourse = React.lazy(() => import("./views/User/MyCourse/assignCourse"));
const CourseDetail = React.lazy(() => import("./views/User/MyCourse/courseDetail"));
const MyCourse = React.lazy(() => import("./views/User/MyCourse/myCourse"));
const MyComingSoon = React.lazy(() => import("./views/User/MyCourse/courseComingSoon"));
const OnlineClass = React.lazy(() => import("./views/User/MyCourse/onlineclass"));
const MyCertificate = React.lazy(() => import("./views/User/MyCertificate/myCertificate"));
const CertificateDetail = React.lazy(() => import("./views/User/MyCertificate/CertificateDetail"));
const MyLicense = React.lazy(() => import("./views/User/MyLicense/myLicense"));
const LicenseDetail = React.lazy(() => import("./views/User/MyLicense/LicenseDetail"));
const MyHistory = React.lazy(() => import("./views/User/MyHistory/myHistory"));
const AllSkill = React.lazy(() => import("./views/User/Skill/SkillHomepage"));
const MySkills = React.lazy(() => import("./views/User/Skill/MySkill"));
const SkillDetail = React.lazy(() => import("./views/User/Skill/SkillDetail"));
const SkillMappingUser = React.lazy(() => import("./views/User/Skill/SkillMapping"));
const AppointChallenge = React.lazy(() => import("./views/User/Skill/AppointChallenge"));
const RequestExternal = React.lazy(() => import("./views/User/RequestExternal/AddExternal"));

const MyQuiz = React.lazy(() => import("./views/User/Quiz/MyQuiz"));
const TakeAssessment = React.lazy(() => import("./views/User/Quiz/TakeAssessment"));

const MyOJT = React.lazy(() => import("./views/User/MyOJT/MyOJT"));
const StatusOJT = React.lazy(() => import("./views/User/MyOJT/StatusOJT"));

const CardsCompetency = React.lazy(() => import("./views/User/Competency/CardsCompetency"));
const CompetencyEvaluate = React.lazy(() => import("./views/User/Competency/CompetencyEvaluate"));

const UserDasdboard = React.lazy(() => import("./views/User/Dashboard"))

const PotentialPersonel = React.lazy(() => import("./views/User/Potential/PotentialPersonel"));
const PotentialTest = React.lazy(() => import("./views/User/Potential/PotentialTest"));
const StatusUser = React.lazy(() => import("./views/User/Status/StatusUser"));



//USERIDP
const DialogueUser = React.lazy(() => import("./views/User/IDP/DialogueUser"));
const UserIDP = React.lazy(() => import("./views/User/IDP/MyIDP"));

// const UserHomepage = React.lazy(() => import("./views/Homepage/Homepage"));
// const CourseDetails = React.lazy(() =>
//   import("./views/Admin/HistoryCourses/CourseDetails")
// );
// const DialogueUser = React.lazy(() =>
//   import("./views/DialogueUser/DialogueUser")
// );


//Assessor
const AppointTest = React.lazy(() => import("./views/Assessor/approve/ApproveTest"));
const MySkillList = React.lazy(() => import("./views/Assessor/allSkill/MySkillList"));
const SkillAssessed = React.lazy(() => import("./views/Assessor/allSkill/SkillAssessed"));
const StatusAssessor = React.lazy(() => import("./views/Assessor/Status/StatusAssessor"));

////////////////////


//Dashboard


const AddQuiz = React.lazy(() => import("./views/Quiz/Add/AddQuiz"));
const AddQuizChoice = React.lazy(() => import("./views/Quiz/Add/Choice"));
const AddQuizFill = React.lazy(() => import("./views/Quiz/Add/Fill/Fill"));
const AddQuizOJT = React.lazy(() => import("./views/Quiz/Add/OJT/AddOJT"));

//Admin Institute
// const DashboardAdminIns = React.lazy(() =>
//   import("./views/AdminIns/DashboardAdminIns/Index")
// );
// const AdminIns_AddCourseOnline = React.lazy(() =>
//   import("./views/AdminIns/AddCourse/AddCourseOnline")
// );
// const AdminIns_AddCourseClass = React.lazy(() =>
//   import("./views/AdminIns/AddCourse/AddCourseClass")
// );
// const AdminIns_EmpRegister = React.lazy(() =>
//   import("./views/AdminIns/Setting/CourseRegister/EmpRegister")
// );
// const AdminIns_RegisterList = React.lazy(() =>
//   import("./views/AdminIns/Setting/CourseRegister/RegisterList")
// );
// const AdminIns_AttendTrainingCourses = React.lazy(() =>
//   import("./views/AdminIns/Setting/AttendTraining/AttendTraningCourses")
// );
// const AdminIns_AttendTrainingEmp = React.lazy(() =>
//   import("./views/AdminIns/Setting/AttendTraining/AttendTrainingEmp")
// );
const AdminIns_AddTeacher = React.lazy(() =>
  import("./views/AdminIns/Teacher/AddTeacher/AddTeacher")
);
// const AdminIns_HistoryCourses = React.lazy(() =>
//   import("./views/AdminIns/HistoryCourses/HistoryCourses")
// );

///Authentication
const LogIn = React.lazy(() => import("./views/Authentication/LoginPage"));
const Homepage = React.lazy(() => import("./views/Authentication/homepage"));
const UnauthorizedPage = React.lazy(() =>
  import("./views/error/UnauthorizedPage")
);


const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
}));

function Routers() {
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  // const RedirectLogin = () => (
  //   <Route>
  //     <Redirect to="/login" />
  //   </Route>
  // );

  // const LoginRoute = ({ component: Component, ...rest }) => {
  //   if (currentUser) {
  //     return (
  //       <Route
  //         {...rest}
  //         render={(props) =>
  //           [
  //             "ROLE_ADMIN",
  //             "ROLE_USER",
  //             "ROLE_TRAINING",
  //             "ROLE_VENDOR",
  //             "ROLE_MANAGER",
  //           ].some((r) => currentUser.roles.indexOf(r) >= 0) ? (
  //             <Component {...props} />
  //           ) : (
  //             <Redirect to="/unauthorized" />
  //           )
  //         }
  //       />
  //     );
  //   } else {
  //     return <RedirectLogin />;
  //   }
  // };

  // const AdminRoute = ({ component: Component, ...rest }) => {
  //   if (currentUser) {
  //     return (
  //       <Route
  //         {...rest}
  //         render={(props) =>
  //           currentUser.roles.includes("ROLE_ADMIN") === true ? (
  //             <Fragment>
  //               {userProfile ? (
  //                 <Component {...props} />
  //               ) : (
  //                 <AnimationBackdrop open={!userProfile} />
  //               )}
  //             </Fragment>
  //           ) : (
  //             <Redirect to="/unauthorized" />
  //           )
  //         }
  //       />
  //     );
  //   } else {
  //     return <RedirectLogin />;
  //   }
  // };

  return (
    // <Fragment>
    <Container className={classes.content} maxWidth={false}>
    {/* <Container maxWidth="lg" style={{ backgroundColor: "transparent" }}> */}
      <Suspense fallback={<div>Loading..</div>}>
        <Switch>
          <Route exact path="/" component={LogIn} />
          <Route exact path="/homepage" component={Homepage} />

          {/* Authentication */}
          {/*  <Route path="/Register" component={Register} /> */}
          <Route path="/unauthorized" component={UnauthorizedPage} />

          {/* Admin And Manager */}
          <Route path="/view/person/Employee" component={EmployeeInfo} />






          {/* Admin */}
          <Route path="/AddCourse/:type" component={AddCourse} />
          {/* <Route path="/Admin/ListopenCourse" component={ListopenCourse} /> */}
          <Route path="/Admin/openCourse/:courseCode" component={openCourse} />
          <Route path="/Admin/openCourse" component={ListopenCourse} />
          <Route path="/Admin/LearningManagement" component={AssignCourse} />
          <Route path="/Admin/EmployeeCourse" component={EmployeeCourse} />
          <Route path="/Admin/EmployeeSkill" component={EmployeeSkill} />
          <Route path="/Admin/PositionCourse" component={PositionCourse} />
          <Route path="/Admin/PositionSkill" component={SkillSetting} />
          <Route path="/Admin/PositionCompetency" component={CompetencySetting} />
          <Route path="/Admin/activeCourse/:courseType" component={ListOpenAll} />
          <Route path="/Admin/activeCourse" component={ActiveCourse} />
          <Route path="/Admin/editMandatory" component={editMandatory} />
          <Route path="/Admin/editCourse" component={editCourse} />
          <Route path="/Admin/editVideo" component={editVDO} />
          <Route path="/EmpHistoryCourse/:name" component={EmpHistoryCourse} />
          <Route path="/Admin/EmployeeRegister" component={EmpRegister} />
          <Route path="/Admin/skill/AddSkill" component={AddSkill} />
          <Route path="/Admin/skill/Allskill" component={AdminAllSkill} />
          <Route path="/skill/emp-skill-detail/:name" component={EmpSkill} />
          <Route path="/skill/view/:name" component={ViewSkill} />
          <Route path="/Admin/person/Assessor" component={AddAssessor} />
          <Route path="/Admin/person/Teacher/Add" component={AddTeacher} />
          <Route path="/Admin/person/Teacher/Edit" component={EditTeacher} />
          <Route path="/Admin/person/Teacher/Profile" component={ProfileTeacher} />
          <Route path="/Admin/person/Teacher" component={ListTeacher} />
          {/* <Route path="/Admin/person/Employee" component={EmployeeInfo} /> */}
          <Route path="/Admin/skill/editInfo/:name" component={EditInfo} />
          <Route path="/Admin/skill/editLevel/:name" component={EditLevel} />
          <Route path="/Admin/skill/SkillMapping" component={SkillMapping} />
          {/* <Route path="/Admin/EmplearnHistory" component={AllLearnHistory} /> */}
          <Route path="/Admin/History/External/Add" component={AddExternalHistory} />
          <Route path="/Admin/History/External" component={ListExternal} />
          <Route path="/Admin/History/Internal" component={AddInternalHistory} />
          <Route path="/Admin/History/Courses" component={HistoryCourses} />
          <Route path="/Admin/license/AddLicense" component={AddLicense} />
          <Route path="/license/Emp-license/:name" component={EmpLicense} />
          <Route path="/license/Emp-licensedetail" component={licensedetail} />
          <Route path="/certificate/Emp-certificate/:name" component={EmpCertificate} />
          <Route path="/certificate/Emp-certificatedetail" component={EmpCertificatedetail} />

          <Route path="/Admin/LessonQuiz/AddQuestion" component={AddLessonQuestion} />
          <Route path="/Admin/LessonQuiz" component={LessonQuiz} />
          <Route path="/Admin/RushQuiz/AddQuestion" component={RushAddQuestion} />
          <Route path="/Admin/RushQuiz" component={RushQuiz} />
          

          <Route path="/Admin/Competency/Suggest-Competency" component={SuggestCompetency} />
          <Route path="/Admin/competency/AddCompetency" component={AddCompetency} />
          <Route path="/Admin/competency/AllCompetency" component={AdminAllCompetency} />
          <Route path="/Admin/competency/view/:name" component={ViewCompetency} />
          <Route path="/Admin/competency/editInfo/:name" component={EditCompetencyInfo} />
          <Route path="/Admin/competency/editLevel/:name" component={EditCompetencyLevel} />
          <Route path="/Admin/Competency/StatusCompetency" component={EmpCompetencyList} />
          <Route path="/Admin/Competency/EvaluateCompetency/:name" component={EmpCardsCompetency} />
          <Route path="/Admin/Competency/LeaderView" component={LeaderCardsCompetency} />


          <Route path="/Admin/OJT/createOJT" component={AdminCreateOJT} />
          <Route path="/Admin/OJT/AllOnJobTraining" component={AdminAllOJT} />
          {/* <Route path="/Admin/OJT/view-allstatus" component={AdminAllStatusOJT} /> */}
          <Route path="/Admin/OJT/view-status/:name" component={AdminStatusOJT} />
          <Route path="/Admin/OJT/assign-user/:name" component={AdminAssignOJT} />
          <Route path="/Admin/OJT/editInfo/:name" component={AdminEditOJT} />
          <Route path="/Admin/OJT/duplicate/:name" component={AdminDuplicateOJT} />

          <Route path="/Admin/Potential" component={PotentialAdmin} />

          <Route path="/Admin/Dashboard" component={Dasdboard} />
          <Route path="/Admin/Status/StatusAdmin" component={StatusAdmin} />

          {/* report */}
          <Route path="/Admin/report-center" component={AllReportCenter} />
          <Route path="/individual-training-hours" component={IndividualTrainingHoursPage} />

          {/* <Route path="/all-idp" component={All_IDP} />
          <Route path="/idp-approval" component={IDPApproval} /> */}
          {/* <Route path="/manage-teacher" component={ManageTeacher} /> */}
          {/* <Route path="/edit-course-online/:name" component={EditCourseOnline} />
          <Route path="/edit-course-class/:name" component={EditCourseClass} /> */}
          {/* <Route path="/edit-teacher/:name" component={EditTeacher} /> */}

          {/* Manager */}
          {/* <Route path="/EmpHistoryList/:name" component={EmpHistoryList} /> */}
          <Route path="/EmpHistoryCourse/:name" component={EmpHistoryCourse} />
          {/* <Route path="/manager/approve" component={ManagerApprove} /> */}
          {/* <Route path="/manager/EmplearnHistory" component={TeamLearnHistory} /> */}


          {/* <Route path="/manager/Employee" component={EmployeeInfo} /> */}


          <Route path="/skill/view/:name" component={SkillDetail} />
          <Route path="/manager/IDP/:name" component={UserIDP} />
          <Route path="/manager/Competency/TeamCompetency" component={TeamCompetencyList} />
          <Route path="/manager/Competency/EmpCompetency/:name" component={TeamCardsCompetency} />
          <Route path="/manager/Competency/EmpEvaluate/:name" component={TeamCompetencyEvaluate} />
          <Route path="/manager/Competency/Leader" component={CompetencyLeader} />
          <Route path="/manager/Competency/LeaderView" component={CompetencyLeaderView} />


          <Route path="/manager/OJT/createOJT" component={TeamCreateOJT} />
          <Route path="/manager/OJT/AllOnJobTraining" component={TeamAllOJT} />
          {/* <Route path="/manager/OJT/view-allstatus" component={TeamAllStatusOJT} /> */}
          <Route path="/manager/OJT/view-status/:name" component={TeamStatusOJT} />
          <Route path="/manager/OJT/assign-user/:name" component={TeamAssignOJT} />
          <Route path="/manager/OJT/editInfo/:name" component={TeamEditOJT} />
          <Route path="/manager/OJT/duplicate/:name" component={TeamDuplicateOJT} />

          <Route path="/manager/Status/StatusManager" component={StatusManager} />

          {/* User */}
          {/* <Route path="/Homepage" component={UserHomepage} /> */}
          <Route path="/category/:category" component={Usercategory} />
          <Route path="/My-AssignCourse/:courseCode" component={MyAssignCourseCode} />
          <Route path="/My-AssignCourse" component={MyAssignCourse} />
          <Route path="/My-Course" component={MyCourse} />
          <Route path="/My-ComingSoon" component={MyComingSoon} />
          <Route path="/MyCertificate" component={MyCertificate} />
          <Route path="/CertificateDetail" component={CertificateDetail} />
          <Route path="/MyLicense" component={MyLicense} />
          <Route path="/LicenseDetail" component={LicenseDetail} />
          <Route path="/CourseDetail" component={CourseDetail} />
          <Route path="/OnlineClass" component={OnlineClass} />
          <Route path="/MyHistory" component={MyHistory} />
          <Route path="/IDP/User/Dialogue" component={DialogueUser} />
          <Route path="/IDP/User/MyIDP" component={UserIDP} />
          <Route path="/AllSkill" component={AllSkill} />
          <Route path="/MySkills" component={MySkills} />
          <Route path="/SkillDetail/:nameSkill" component={SkillDetail} />
          <Route path="/Appointment/:nameSkill" component={AppointChallenge} />
          <Route path="/RequestExternal" component={RequestExternal} />

          <Route path="/LessonQuiz/TakeAssessment" component={TakeAssessment} />
          <Route path="/LessonQuiz" component={MyQuiz} />

          


          <Route path="/My-OnJobTraining" component={MyOJT} />
          <Route path="/Status-OnJobTraining" component={StatusOJT} />

          {/* <Route path="/CourseDetails/:name" component={CourseDetails} /> */}
          <Route path="/SkillMappingUser" component={SkillMappingUser} />
          <Route path="/User/competency/Evaluate" component={CompetencyEvaluate} />
          <Route path="/User/competency/MyCompetency" component={CardsCompetency} />

          <Route path="/User/Potential/Test" component={PotentialTest} />
          <Route path="/User/Potential" component={PotentialPersonel} />

          <Route path="/User/Course/:courseType/:category" component={AllCourse} />
          <Route path="/User/Course/:courseType/" component={AllCourse} />

          <Route path="/User/Dashboard" component={UserDasdboard} />
          <Route path="/User/Status/StatusUser" component={StatusUser} />


          {/* Assessor */}
          <Route path="/Assessor/AppointTest" component={AppointTest} />
          <Route path="/Assessor/MySkillList" component={MySkillList} />
          <Route path="/Assessor/SkillAssessed/:name" component={SkillAssessed} />
          <Route path="/Assessor/Status/StatusAssessor" component={StatusAssessor} />

          {/* ETC */}

          {/* Dashboard */}


          <Route path="/addquiz" component={AddQuiz} />
          <Route path="/addquiz-choice" component={AddQuizChoice} />
          <Route path="/addquiz-fill" component={AddQuizFill} />
          <Route path="/create/on-job-training" component={AddQuizOJT} />

          {/* Admin institute */}
          {/* <Route path="/Dashboard/AdminIns" component={DashboardAdminIns} />
          <Route
            path="/AdminIns/AddCourseClass"
            component={AdminIns_AddCourseClass}
          />
          <Route
            path="/AdminIns/AddCourseOnline"
            component={AdminIns_AddCourseOnline}
          />
          <Route
            path="/AdminIns/CourseRegister/EmpRegister"
            component={AdminIns_EmpRegister}
          />
          <Route
            path="/AdminIns/RegisterList/:name"
            component={AdminIns_RegisterList}
          />
          <Route
            path="/AdminIns/AttendTrainingCourses"
            component={AdminIns_AttendTrainingCourses}
          />
          <Route
            path="/AdminIns/AttendTrainingEmp/:name"
            component={AdminIns_AttendTrainingEmp}
          />
          */}

          <Route path="/AdminIns/AddTeacher" component={AdminIns_AddTeacher} />
          {/*
          <Route
            path="/AdminIns/HistoryCourses"
            component={AdminIns_HistoryCourses}
          /> */}
        </Switch>
      </Suspense>
    </Container>
    // </Fragment>
  );
}

export default Routers;
