import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Box, Breadcrumbs, Container, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ButtonBlue from "../../../views/shared/ButtonBlue";
import { IndividualTrainingHoursXlsx } from "./xlsx-export/IndividualTrainingHoursXlsx";
import { Link } from "react-router-dom";
import AlertResponse from "../../../views/shared/AlertResponse";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CardStyle from "../../../views/Authentication/components/LoginCard";
import { getUserCompany, getUserDepartment, getUserDivision, getUserFullName, getUserPosition, getUserSection } from "../../../utils/userData";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
    "& .search-filter-container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .filter-wrap": {},
        "& .action-container": {},
    },
});

const StyledBox = styled(Box)({
    "&.open": {
        paddingLeft: 16,
        width: "calc(100% - 1000px)",
    },
    "&.close": {
        paddingLeft: 0,
        width: "100%",
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    whiteSpace: "nowrap",
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "20px 0px 20px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 24,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    padding: 8,
    whiteSpace: "nowrap",
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 0,
    },
    "&:last-of-type": {
        paddingRight: 24,
    },
}));

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

export const IndividualTrainingHoursPage = () => {
    const { t } = useTranslation();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [traningData, setTraningData] = useState(null);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    // const onChangeCompany = (newValue) => {
    //     setSelectedCompany(newValue);
    // };

    const columns = [
        { id: "JopPosition", label: t("Position"), minWidth: 170 },
        { id: "Company", label: t("Company"), minWidth: 170 },
        { id: "Division", label: t("Division"), minWidth: 170 },
        { id: "Department", label: t("Department"), minWidth: 170 },
        { id: "Section", label: t("Section"), minWidth: 100 },
    ];

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/Admin/report-center"}
                    >
                        Report
                    </Link>
                    <Typography color="text.primary">ชั่วโมงอบรมรายคน</Typography>
                </Breadcrumbs>

                <Typography variant="h4" sx={{ mt: 3 }}>
                    ชั่วโมงอบรมรายคน
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            Individual Training Hours
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={1} direction={"row"} justifyContent={"flex-end"}>
                            <ButtonBlue
                                variant={"contained"}
                                onClick={() => IndividualTrainingHoursXlsx()}
                            >
                                Download
                            </ButtonBlue>
                        </Stack>
                    </Grid>
                </Grid>

                <CardStyle style={{ marginTop: "3vh" }}>
                    <Box style={{ padding: "24px" }}>
                        <Box style={{ display: "flex", position: "relative" }}>
                            <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledCellHeader
                                                align={"center"}
                                                className="sticky"
                                                style={{ minWidth: 240 }}
                                            >
                                                {t("FullName")}
                                            </StyledCellHeader>

                                            {columns.map((column) => (
                                                <StyledCellHeader key={column.id}>
                                                    {column.label}
                                                </StyledCellHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isFetching === true ? (
                                            <StyledRowContent>
                                                <StyledCellContent
                                                    colSpan={5}
                                                    style={{ height: 150 }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        {/* <img alt="loading" src={loading} style={{ width: 100, height: 100 }} /> */}
                                                    </Box>
                                                </StyledCellContent>
                                            </StyledRowContent>
                                        ) : (
                                            <Fragment>
                                                {traningData && traningData.length > 0 ? (
                                                    traningData
                                                        // .slice(
                                                        //     page * rowsPerPage,
                                                        //     page * rowsPerPage + rowsPerPage
                                                        // )
                                                        // .filter((row) => {
                                                        //     const fullname = `${row.firstname_TH + " " + row.lastname_TH
                                                        //         }`.toLowerCase();
                                                        //     return fullname.includes(
                                                        //         searchText.toLowerCase()
                                                        //     );
                                                        // })
                                                        .map((row, index) => (
                                                            <StyledRowContent hover key={index}>
                                                                <StyledCellContent
                                                                    className="sticky"
                                                                    style={{
                                                                        borderBottom:
                                                                            "1px dashed rgba(224, 224, 224, 1)",
                                                                    }}
                                                                >
                                                                    <div className="avatar">
                                                                        <Avatar alt={row.employeeID} src={`${row.imageProfile}`} />
                                                                        <div>
                                                                            <Typography className="fullname">
                                                                                {getUserFullName(row)
                                                                                    ? getUserFullName(row)
                                                                                    : row.firstname_TH +
                                                                                    " " +
                                                                                    row.lastname_TH}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserPosition(row) ? getUserPosition(row) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserCompany(row) ? getUserCompany(row) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserDivision(row) ? getUserDivision(row) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserDepartment(row) ? getUserDepartment(row) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>
                                                                        {getUserSection(row) ? getUserSection(row) : "-"}
                                                                    </Typography>
                                                                </StyledCellContent>
                                                            </StyledRowContent>
                                                        ))
                                                ) : (
                                                    <StyledRowContent>
                                                        <StyledCellContent
                                                            colSpan={5}
                                                            style={{ height: 150 }}
                                                        >
                                                            <Typography align="center">
                                                                {t("NoEmployee")}
                                                            </Typography>
                                                        </StyledCellContent>
                                                    </StyledRowContent>
                                                )}
                                            </Fragment>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </CardStyle>
            </Container>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </StyledRoot>
    );
};